import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Drawer, {
  DrawerItem,
  DrawerItemContent,
  DrawerItemTitle
} from 'dpl/common/components/Drawer';

export default function TransportationDetails({
  name,
  transportMethods,
  breederFirstName,
  className
}) {
  return (
    <div className={classnames('mb5 mb8-md', name, className)}>
      <h5 className="font-24 mb4">Puppy transport options</h5>
      <p className="mb1 font-16 secondary mb4">
        {breederFirstName} offers options to safely drop-off your new puppy.
      </p>
      {transportMethods.map((tm, idx) => (
        <Drawer key={idx} scrollToTop={false}>
          <DrawerItem
            itemKey={tm.key}
            className={classnames({
              'bt b--subtle-gray': idx > 0
            })}
          >
            <DrawerItemTitle
              iconClassName="icon-default"
              iconOpenName="fetch-chevron-up"
              iconCloseName="fetch-chevron-down"
            >
              <p className="flex-auto font-16 fw-medium">{tm.title}</p>
            </DrawerItemTitle>
            <DrawerItemContent>
              <p className="font-16 secondary">{tm.longDescription}</p>
              {tm.costDescription && (
                <p className="font-14 secondary mt2">{tm.costDescription}</p>
              )}
            </DrawerItemContent>
          </DrawerItem>
        </Drawer>
      ))}
    </div>
  );
}

TransportationDetails.propTypes = {
  name: PropTypes.string.isRequired,
  transportMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  breederFirstName: PropTypes.string,
  className: PropTypes.string
};

TransportationDetails.defaultProps = {
  breederFirstName: 'Your breeder',
  className: null
};
