import {
  TRANSPORT_CATEGORY as transportCategory,
  TRANSPORT_KEYS as transportKeys,
  TRANSPORT_TYPES as transportTypes
} from 'dpl/breeder_search_results/utils/constants';

export const TRANSPORT_CATEGORY = transportCategory;
export const TRANSPORT_KEYS = transportKeys;
export const TRANSPORT_TYPES = transportTypes;

export function getTransportDropOffInfo({
  breederFirstName = null,
  puppyName = null
}) {
  return [
    {
      title: `Ground transporter`,
      longDescription: `Work with ${
        breederFirstName || 'your breeder'
      } and an experienced, reputable transporter to have your puppy driven to your home. ${
        breederFirstName || puppyName
          ? 'Your puppy will be in the transporter’s care at all times during travel.'
          : ''
      }`,
      costDescription: 'Cost: $300-$1,000 depending on your location',
      type: TRANSPORT_CATEGORY.DROPOFF,
      key: TRANSPORT_KEYS.GROUND_TRANSPORT
    },
    {
      title: `Flight nanny`,
      longDescription: `Work with ${
        breederFirstName || 'your breeder'
      } to find a chaperone to fly with your puppy to an airport near you. Your flight nanny will help keep your puppy safe and comfortable in the main cabin of the plane. ${
        breederFirstName || puppyName
          ? 'You can pick up your puppy at the airport or see if your flight nanny can drop your puppy off at home.'
          : ''
      }`,
      costDescription: 'Cost: $600-900 depending on location and airline',
      type: TRANSPORT_CATEGORY.DROPOFF,
      key: TRANSPORT_KEYS.FLIGHT_NANNY
    },
    {
      title: `Pet-safe flight`,
      longDescription: `Work with ${
        breederFirstName || 'your breeder'
      } to have your puppy flown to an airport near you. ${
        breederFirstName
          ? `${breederFirstName} will drop your puppy off at their airport.`
          : ''
      } Your puppy will be in a safe cargo environment and you’ll pick them up at the airport.`,
      costDescription: 'Cost: $200-500 depending on location and airline',
      type: TRANSPORT_CATEGORY.DROPOFF,
      key: TRANSPORT_KEYS.AIR_CARGO
    }
  ];
}
