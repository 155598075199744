import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import BreederProfileLittersAndPuppiesTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileLittersAndPuppiesTabContainer';
import BreederProfileOverviewTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileOverviewTabContainer';
import BreederProfileOwnerStoriesTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileOwnerStoriesTabContainer';
import BreederProfileUpdatesTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileUpdatesTabContainer';
import { trackPageView } from 'dpl/util/analytics';
import useBreederSearchUpdatesExp from 'dpl/experiments/exp_breeder_search_updates/breeder_profile/hooks/useBreederSearchUpdatesExp';
import BreederProfilePuppiesTab from 'dpl/experiments/exp_breeder_search_updates/breeder_profile/components/BreederProfilePuppiesTab';

import useBreederProfileTabs from '../../hooks/useBreederProfileTabs';
import {
  PAGE_CATEGORIES,
  PAGE_CATEGORIES_EXPERIMENT,
  TAB_KEYS
} from '../../utils/constants';

export default function BreederProfileTabContent() {
  const { activeTabKey } = useBreederProfileTabs();
  const { isExperiment } = useBreederSearchUpdatesExp();

  const { analyticsPageName, breederProfileId } = useSelector(
    ({ server }) => server
  );

  const tabContent = {
    [TAB_KEYS.OVERVIEW]: BreederProfileOverviewTabContainer,
    [TAB_KEYS.DOGS]: isExperiment
      ? BreederProfilePuppiesTab
      : BreederProfileLittersAndPuppiesTabContainer,
    [TAB_KEYS.UPDATES]: BreederProfileUpdatesTabContainer,
    /* TODO: Uncomment when ready */
    // [TAB_KEYS.PROGRAM]: BreederProfileProgramTabContainer,
    [TAB_KEYS.STORIES]: BreederProfileOwnerStoriesTabContainer
  };

  const pageCategories = isExperiment
    ? PAGE_CATEGORIES_EXPERIMENT
    : PAGE_CATEGORIES;

  const TabContent = tabContent[activeTabKey];

  useEffect(() => {
    trackPageView({
      name: analyticsPageName,
      category: pageCategories[activeTabKey],
      properties: { breeder_profile_id: breederProfileId }
    });
  }, [activeTabKey]);

  return <TabContent />;
}
