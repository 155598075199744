import React from 'react';
import PropTypes from 'prop-types';

import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';

import {
  TRANSPORT_CATEGORY,
  TRANSPORT_TYPES,
  getTransportDropOffInfo
} from './utils/constants';
import TransportationDetails from './TransportationDetails';
import PickupOptionDetails from './PickupOptionDetails';

const { AIR, GROUND, PICKUP } = TRANSPORT_TYPES;
const NEARBY_DISTANCE = 200;

function getDeliveryMethodKeys(deliveryMethods) {
  const allMethods = [];
  if (deliveryMethods[AIR]) {
    allMethods.push(...deliveryMethods[AIR]);
  }
  if (deliveryMethods[GROUND]) {
    allMethods.push(...deliveryMethods[GROUND]);
  }
  if (deliveryMethods[PICKUP]) {
    allMethods.push(...deliveryMethods[PICKUP]);
  }

  return allMethods.map(dm => dm.key);
}

export default function TransportationModal({
  onCloseClick,
  deliveryMethods,
  breederFirstName,
  puppyName,
  pickupOptions,
  distance
}) {
  let transportModalInfo = getTransportDropOffInfo({
    breederFirstName,
    puppyName
  });

  if (deliveryMethods) {
    const deliveryMethodKeys = getDeliveryMethodKeys(deliveryMethods);
    const availableDeliveryMethods = transportModalInfo.filter(tm =>
      deliveryMethodKeys.includes(tm.key)
    );
    transportModalInfo = availableDeliveryMethods;
  }

  const dropOffTransportation = transportModalInfo.filter(
    tm => tm.type === TRANSPORT_CATEGORY.DROPOFF
  );

  const isNearby = distance <= NEARBY_DISTANCE;

  return (
    <MiniOverlayModal
      className="TransportationModal"
      contentClassName="bg-white br3"
      onCloseClick={onCloseClick}
    >
      <div className="pv8 ph8 ph16-sm pv14-sm">
        {isNearby ? (
          <>
            {pickupOptions.length > 0 && (
              <PickupOptionDetails
                breederFirstName={breederFirstName}
                pickupOptions={pickupOptions}
              />
            )}
            {dropOffTransportation.length > 0 && (
              <TransportationDetails
                className="mt2"
                name="AirTransportation"
                transportMethods={dropOffTransportation}
                breederFirstName={breederFirstName}
              />
            )}
          </>
        ) : (
          <>
            {dropOffTransportation.length > 0 && (
              <TransportationDetails
                name="AirTransportation"
                transportMethods={dropOffTransportation}
                breederFirstName={breederFirstName}
              />
            )}
            {pickupOptions.length > 0 && (
              <PickupOptionDetails
                breederFirstName={breederFirstName}
                pickupOptions={pickupOptions}
              />
            )}
          </>
        )}
      </div>
    </MiniOverlayModal>
  );
}

TransportationModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  deliveryMethods: PropTypes.object,
  breederFirstName: PropTypes.string,
  puppyName: PropTypes.string,
  pickupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      distance: PropTypes.number,
      key: PropTypes.string
    })
  ),
  distance: PropTypes.number
};

TransportationModal.defaultProps = {
  breederFirstName: null,
  puppyName: null,
  deliveryMethods: null,
  pickupOptions: [],
  distance: 0
};
