import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { pushParams } from 'dpl/actions/query_params';
import { LITTER_ID } from 'dpl/constants/query_params';
import { LitterCard } from 'dpl/containers/BreederProfile/Show/BreederProfileLittersSectionContainer';
import BreederProfileLittersSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileLittersSkeleton';
import SectionViewTracker from 'dpl/common/components/SectionViewTracker';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';

import useLitters from '../hooks/useLitters';

const MAX_LITTERS_TO_SHOW = 3;

export default function UpcomingLittersList() {
  const dispatch = useDispatch();
  const { isFetchingCurrentLitters, upcomingLitters, sortedAvailablePuppies } =
    useLitters();
  const { analyticsPageName, breederProfileId } = useSelector(
    ({ server }) => server
  );

  const [isExpanded, toggleIsExpanded] = useReducer(
    expanded => !expanded,
    false
  );

  if (isFetchingCurrentLitters) {
    return <BreederProfileLittersSkeleton />;
  }

  if (upcomingLitters.length === 0) {
    return null;
  }

  const hasPuppies = sortedAvailablePuppies.length > 0;
  const hasMoreLitters = upcomingLitters.length > MAX_LITTERS_TO_SHOW;

  function onLitterClick(litterId, analytics) {
    sendRudderstackEvent(ANALYTICS_EVENTS.CARD_CLICKED_BY_USER, {
      ...analytics,
      view: ANALYTICS_VIEWS.BREEDER_PROFILE,
      view_feature: ANALYTICS_VIEW_FEATURES.LITTER_CARD,
      view_feature_location: ANALYTICS_VIEW_FEATURE_LOCATIONS.UPCOMING_LITTERS,
      breeder_profile_id: breederProfileId,
      litter_id: litterId
    });

    dispatch(pushParams({ [LITTER_ID]: litterId }, { hash: true }));
  }

  return (
    <SectionViewTracker
      name={analyticsPageName}
      category="breeder-profile-tab-puppies-upcoming-litters"
      properties={{ breeder_profile_id: breederProfileId }}
    >
      <div
        className={classnames('UpcomingLittersList container-xxl', {
          pv6: hasPuppies,
          pb6: !hasPuppies
        })}
      >
        <h3 className="mb6 font-24 lh-title">Upcoming litters</h3>
        <div className="BreederProfileLittersList grid gap-8">
          {upcomingLitters.map((litter, idx) => (
            <div key={litter.id} className="flex ws-normal">
              <LitterCard
                litter={litter}
                onLitterClick={onLitterClick}
                analytics={{ position: idx + 1 }}
              />
            </div>
          ))}
        </div>

        {hasMoreLitters && (
          <button
            onClick={toggleIsExpanded}
            className="dn dib-ns underline stone-700 font-16 mt6"
            type="button"
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
    </SectionViewTracker>
  );
}
