import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import {
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import BreederProfileFollowButton from 'dpl/favorites/components/BreederProfileFollowButton';
import useUserFollowsDataV2 from 'dpl/favorites/hooks/useUserFollowsDataV2';
import useBreederSearchUpdatesExp from 'dpl/experiments/exp_breeder_search_updates/breeder_profile/hooks/useBreederSearchUpdatesExp';

const DEFAULT_ANALYTICS = {
  view: ANALYTICS_VIEWS.BREEDER_PROFILE,
  view_feature: ANALYTICS_VIEW_FEATURES.FAVORITE_HEART_ICON,
  view_feature_location: ANALYTICS_VIEW_FEATURE_LOCATIONS.BREEDER_PROFILE_HEADER
};

export default function BreederProfileFollowButtonContainer({
  className,
  onLoad,
  analytics
}) {
  const { isBreeder } = useCurrentUser();
  const { isExperiment } = useBreederSearchUpdatesExp();

  const { breederBreeds, breederProfileId, breederDisplayName } = useSelector(
    ({ server }) => server
  );

  const breederBreedIds = breederBreeds.map(bb => bb.id);

  const { isLoading } = useUserFollowsDataV2(breederBreedIds, breederProfileId);

  const shouldRender = !isBreeder && !isExperiment;

  useEffect(() => {
    if (!isLoading) {
      onLoad?.({ rendered: shouldRender });
    }
  }, [isLoading, shouldRender]);

  if (!shouldRender) return null;

  return (
    <BreederProfileFollowButton
      analytics={{ ...analytics, breeder_name: breederDisplayName }}
      className={className}
      breederBreedIds={breederBreedIds}
      breederProfileId={breederProfileId}
      withIcon
    />
  );
}

BreederProfileFollowButtonContainer.propTypes = {
  className: PropTypes.string,
  onLoad: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  analytics: PropTypes.object
};

BreederProfileFollowButtonContainer.defaultProps = {
  className:
    'flex items-center justify-center font-16 fw-medium stone-700 ba b--gray-300 br-pill pv2 ph3',
  onLoad: null,
  analytics: DEFAULT_ANALYTICS
};
