import React from 'react';
import { useSelector } from 'react-redux';

import PuppiesList from './PuppiesList';
import UpcomingLittersList from './UpcomingLittersList';
import ReservedAndPastPuppiesList from './ReservedAndPastPuppiesList';

export default function BreederProfilePuppiesTab() {
  const { hasCurrentLitters, hasPastPuppies, reservedPuppyCount } = useSelector(
    ({ server }) => server
  );

  return (
    <div className="BreederProfilePuppiesTab">
      {hasCurrentLitters && (
        <>
          <PuppiesList />
          <UpcomingLittersList />
        </>
      )}
      {(hasPastPuppies || reservedPuppyCount > 0) && (
        <ReservedAndPastPuppiesList />
      )}
    </div>
  );
}
