import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  LITTER_DISPLAY_STATES,
  LITTER_STATES
} from 'dpl/shared/constants/shared';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { littersResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { nameUnnamedPuppies } from 'dpl/shared/utils/breeder_app';

export default function useLitters() {
  const { breederProfileId } = useSelector(({ server }) => server);

  const {
    isFetching: isFetchingCurrentLitters,
    value: { data: currentLitters }
  } = useResourceQuery(
    littersResourceDefinition(breederProfileId, {
      status: LITTER_DISPLAY_STATES.CURRENT,
      exclude_adult: true
    })
  );

  const {
    isFetching: isFetchingPastLitters,
    value: { data: pastLitters }
  } = useResourceQuery(
    littersResourceDefinition(breederProfileId, {
      status: LITTER_DISPLAY_STATES.PAST,
      exclude_adult: true
    })
  );

  const { availablePuppies, reservedPuppies } = useMemo(() => {
    return currentLitters
      .flatMap(litter => nameUnnamedPuppies(litter.puppies))
      .reduce(
        (acc, puppy) => {
          if (puppy.reserved || puppy.snoozed) {
            acc.reservedPuppies.push(puppy);
          } else {
            acc.availablePuppies.push(puppy);
          }

          return acc;
        },
        { availablePuppies: [], reservedPuppies: [] }
      );
  }, [currentLitters]);

  const sortedAvailablePuppies = useMemo(() => {
    return availablePuppies.sort(
      (a, b) => new Date(b.birth_date) - new Date(a.birth_date)
    );
  }, [availablePuppies]);

  const pastPuppies = useMemo(
    () =>
      pastLitters.reduce(
        (pups, litter) => [...pups, ...nameUnnamedPuppies(litter.puppies)],
        []
      ),
    [pastLitters]
  );

  const upcomingLitters = useMemo(
    () => currentLitters.filter(litter => litter.status !== LITTER_STATES.BORN),
    [currentLitters]
  );

  return {
    sortedAvailablePuppies,
    reservedPuppies,
    pastPuppies,
    upcomingLitters,
    isFetchingLitters: isFetchingCurrentLitters && isFetchingPastLitters,
    isFetchingCurrentLitters
  };
}
