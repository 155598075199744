import React from 'react';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { breederPostsQueryDefinition } from 'dpl/breeder_posts/utils/resourceDefinitions';
import BreederPostCardsCarousel from 'dpl/breeder_posts/components/BreederPostCardsCarousel';
import BreederProfileUpdatesSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileUpdatesSkeleton';
import {
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import SectionViewTracker from 'dpl/common/components/SectionViewTracker';

export default function BreederProfileUpdatesSectionContainer() {
  const {
    analyticsPageName,
    breederProfileId,
    hasPastPuppies,
    hasCurrentLitters,
    isSearchEngineReferral
  } = useSelector(({ server }) => server);

  const {
    isLoading,
    refetch,
    value: { data: breederPosts }
  } = useResourceQuery(breederPostsQueryDefinition(breederProfileId));

  if (isLoading) {
    return <BreederProfileUpdatesSkeleton />;
  }

  const showBorder =
    (hasPastPuppies || hasCurrentLitters) && !isSearchEngineReferral;

  if (breederPosts.length === 0 && showBorder) {
    return (
      <div className="container-xxl">
        <div className="bt b--gray-300" />
      </div>
    );
  }

  if (breederPosts.length === 0 && !showBorder) {
    return null;
  }

  return (
    <SectionViewTracker
      name={analyticsPageName}
      category="breeder-profile-updates"
      properties={{ breeder_profile_id: breederProfileId }}
    >
      <section
        className="BreederProfileUpdatesSection pv12 pv20-md overflow-hidden"
        id="updates-section"
      >
        <div className="container-xxl">
          <h2 className="font-24 md:font-40 lh-title mb10 mb16-md">
            Litter and program updates
          </h2>

          <BreederPostCardsCarousel
            analytics={{
              view: ANALYTICS_VIEWS.BREEDER_PROFILE_PAGE,
              view_feature_location:
                ANALYTICS_VIEW_FEATURE_LOCATIONS.OVERVIEW_TAB
            }}
            breederPosts={breederPosts}
            refetchBreederPosts={refetch}
            showLitterTag
          />
        </div>
      </section>
    </SectionViewTracker>
  );
}
