import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import BreederProfilePastPuppiesCarousel from 'dpl/components/BreederProfile/Show/BreederProfilePastPuppiesCarousel';
import BreederProfilePastPuppiesSectionSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfilePastPuppiesSectionSkeleton';

import useLitters from '../hooks/useLitters';

export default function ReservedAndPastPuppiesList() {
  const { breederProfileId, breederFirstName } = useSelector(
    ({ server }) => server
  );

  const { isFetchingLitters, pastPuppies, reservedPuppies } = useLitters();

  if (isFetchingLitters) {
    return <BreederProfilePastPuppiesSectionSkeleton />;
  }

  if (isEmpty(pastPuppies) && isEmpty(reservedPuppies)) {
    return null;
  }

  return (
    <BreederProfilePastPuppiesCarousel
      firstName={breederFirstName}
      breederProfileId={breederProfileId}
      puppies={[...reservedPuppies, ...pastPuppies]}
    />
  );
}
