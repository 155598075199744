import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';

import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import Icon from 'dpl/common/components/Icon';
import BreederProfileIntroSectionSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileIntroSectionSkeleton';
import SmartImage from 'dpl/components/SmartImage';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import {
  breederInterviewResourceDefinition,
  singleBreederProfileResourceDefinition
} from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isLessThanBreakpoint } from 'dpl/util/grid';
import { SECTION_ID as STORIES_SECTION_ID } from 'dpl/containers/BreederProfile/Show/BreederProfileOwnerStoriesContainer';
import FixedTooltipPortal from 'dpl/common/tooltip/components/FixedTooltipPortal';
import SectionViewTracker from 'dpl/common/components/SectionViewTracker';

const IS_LT_MD = isLessThanBreakpoint('md');
const CLAMPED_TEXT_LINE_HEIGHT = IS_LT_MD ? 21.25 : 24;

const QUESTION_KEYS = {
  ABOUT_BREEDER: 'about-breeder',
  ABOUT_PROGRAM: 'about-program'
};

function scrollToSection(sectionId) {
  const section = document.getElementById(sectionId);
  if (section) section.scrollIntoView({ behavior: 'smooth' });
}

function getBreederExperienceItems({
  firstName,
  answers,
  certificationNames,
  clubNames,
  clubCount
}) {
  const aboutBreeder = answers.find(
    answer => answer.question_key === QUESTION_KEYS.ABOUT_BREEDER
  );

  const aboutProgram = answers.find(
    answer => answer.question_key === QUESTION_KEYS.ABOUT_PROGRAM
  );

  return [
    aboutBreeder && {
      heading: `About ${firstName}`,
      description: aboutBreeder.answer,
      iconName: 'fetch-pets-heart'
    },
    aboutProgram && {
      heading: `What makes ${firstName}’s program special`,
      description: aboutProgram.answer,
      iconName: 'fetch-favorite-date'
    },
    clubCount > 0 && {
      heading: `${firstName} is a member of ${
        clubCount === 1 ? 'a club' : [clubCount, 'clubs'].join(' ')
      }`,
      description: clubNames,
      iconName: 'fetch-animal-badge'
    },
    certificationNames && {
      heading: `${firstName}’s certifications & recognitions`,
      description: certificationNames,
      iconName: 'fetch-animal-cup'
    }
  ].filter(Boolean);
}

export default function BreederProfileIntroSection() {
  const [isVisible, toggleIsVisible] = useReducer(visible => !visible, false);
  const {
    analyticsPageName,
    breederProfileId,
    introParagraph,
    testimonialsCount
  } = useSelector(({ server }) => server);

  const { value: breeder, isFetching: isFetchingBreeder } = useResourceQuery(
    singleBreederProfileResourceDefinition(breederProfileId)
  );

  const {
    value: { data: interview },
    isFetching: isFetchingInterview
  } = useResourceQuery(breederInterviewResourceDefinition(breederProfileId));

  if (isFetchingBreeder || isFetchingInterview) {
    return <BreederProfileIntroSectionSkeleton />;
  }

  const breederResponseDescription = [
    breeder.response_rate_description,
    breeder.response_time_description
  ]
    .filter(Boolean)
    .join(' • ');

  const hasClubMembership = breeder.club_count > 0;

  const clubMembershipDescription = hasClubMembership
    ? `${breeder.club_count} club ${
        breeder.club_count > 1 ? 'memberships' : 'membership'
      }`
    : '';

  const experienceItems = getBreederExperienceItems({
    firstName: breeder.user.first_name,
    answers: interview.answers,
    certificationNames: interview.formatted_certifications,
    clubNames: interview.formatted_clubs,
    clubCount: interview.club_count
  });

  return (
    <SectionViewTracker
      name={analyticsPageName}
      category="breeder-profile-tab-about-meet-breeder"
      properties={{ breeder_profile_id: breederProfileId }}
    >
      <div className="BreederProfileIntroSection">
        <h2 className="font-28 md:font-40 lh-title mb5 mb6-md">
          Meet the breeder
        </h2>
        {interview.photo?.url && (
          <div className="aspect-ratio aspect-ratio--6x4 relative mb4">
            <div className="aspect-ratio--object">
              <SmartImage
                Tag="div"
                className="w-100 h-100 cover bg-center"
                wrapperOnlyClassName="br3 overflow-hidden"
                src={interview.photo.url}
              />
            </div>
          </div>
        )}
        <ul className="flex flex-column gap-8 font-14 md:font-18 secondary mb4 pb4 bb b--gray-300">
          <li className="flex gap-8 items-center">
            <Icon
              className="BreederProfileDetailsCard__icon"
              name="fetch-badge-paw"
              height="20px"
              width="20px"
            />
            <span className="lh-1">
              {clubMembershipDescription
                ? `${clubMembershipDescription} • `
                : ''}
              <button
                type="button"
                className="underline"
                onClick={toggleIsVisible}
              >
                Certified breeder
              </button>
            </span>
          </li>
          {breederResponseDescription && (
            <li className="flex gap-8 items-center">
              <Icon
                className="BreederProfileDetailsCard__icon"
                name="fetch-message"
                height="20px"
                width="20px"
              />
              <span className="lh-1">{breederResponseDescription}</span>
            </li>
          )}
          {testimonialsCount > 0 && (
            <li className="flex gap-8 items-center">
              <Icon
                className="BreederProfileDetailsCard__icon"
                name="fetch-check"
                height="20px"
                width="20px"
              />
              <button
                type="button"
                className="underline lh-1"
                onClick={() => scrollToSection(STORIES_SECTION_ID)}
              >
                {testimonialsCount}{' '}
                {testimonialsCount === 1 ? 'review' : 'reviews'}
              </button>
            </li>
          )}
        </ul>

        <ClampedMultiLineText
          className="font-16 md:font-18 secondary"
          text={introParagraph}
          numOfLines={6}
          lineHeight={CLAMPED_TEXT_LINE_HEIGHT}
          moreText="Read more"
          lessText="Show less"
          fadeToButton
          isOpen={window.__DPL_BOT_UA}
        />

        {experienceItems.length > 0 && (
          <div>
            <h3 className="font-20 md:font-24 lh-title mt6 mb4 mb6-md">
              {breeder.user.first_name}’s experience
            </h3>
            <ul className="flex flex-column gap-24 md:gap-32 font-16 md:font-18">
              {experienceItems.map(item => (
                <li key={item.heading}>
                  <div className="flex flex-column flex-md-row gap-8 md:gap-16">
                    <Icon
                      className="secondary"
                      name={item.iconName}
                      height="32px"
                      width="32px"
                    />
                    <div>
                      <p className="fw-medium mb1">{item.heading}</p>
                      <ClampedMultiLineText
                        className="secondary"
                        text={item.description}
                        numOfLines={4}
                        lineHeight={CLAMPED_TEXT_LINE_HEIGHT}
                        moreText="Read more"
                        lessText="Show less"
                        fadeToButton
                        isOpen={window.__DPL_BOT_UA}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        <FixedTooltipPortal
          className="tc pt16 ph5"
          isVisible={isVisible}
          onCloseClick={toggleIsVisible}
        >
          <h6 className="font-20 lh-title mb2">
            Good Dog’s Certified breeders
          </h6>
          <div className="flex flex-column gap-8 font-16 secondary">
            <p>
              Every Good Dog breeder gets certified after our screening team
              evaluates their practices against our veterinary-backed standards.
            </p>
            <p>
              Our certification examines parent dog care, health testing,
              environment, and support so you can connect with ethical breeders
              who put health first and build strong relationships with their
              owners.
            </p>
          </div>
        </FixedTooltipPortal>
      </div>
    </SectionViewTracker>
  );
}
