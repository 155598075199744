import { useState, useCallback } from 'react';

export default function useToggle(initialValue) {
  const [on, setOn] = useState(() => {
    if (typeof initialValue === 'boolean') {
      return initialValue;
    }

    return Boolean(initialValue);
  });

  const handleToggle = useCallback(value => {
    if (typeof value === 'boolean') {
      return setOn(value);
    }

    return setOn(v => !v);
  }, []);

  return [on, handleToggle];
}
