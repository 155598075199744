import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const PICKUP_OPTIONS_PROP = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ])
);
function ExpandablePickupOptions({ options, seeMoreAfter }) {
  const [isShowingMore, setIsShowingMore] = useState(false);

  function renderOptionsComponent(opts) {
    return opts.map((opt, idx) => (
      <div
        key={opt.title}
        className={classnames(
          {
            'bb b--black-10 mb6': idx !== options.length - 1
          },
          'pb4'
        )}
      >
        <div>
          <p className="fw-medium font-16 mb1">{opt.title}</p>
          <p className="secondary font-16">{opt.subtitle}</p>
        </div>
      </div>
    ));
  }

  const pickupButtonCta = isShowingMore ? 'Show fewer' : 'Show all';
  const buttonText = `${pickupButtonCta} pick-up locations`;

  const defaultOptions = options.slice(0, seeMoreAfter);
  const showMoreOptions = options.slice(seeMoreAfter);
  const shouldShowMore = seeMoreAfter > 0 && showMoreOptions.length > 0;

  return (
    <>
      {shouldShowMore ? (
        <>
          {renderOptionsComponent(
            defaultOptions,
            !isShowingMore || !shouldShowMore
          )}
          {isShowingMore && renderOptionsComponent(showMoreOptions, true)}

          <button
            type="button"
            onClick={() => setIsShowingMore(!isShowingMore)}
            className="underline stone-700 font-16 fw-medium"
          >
            {buttonText}
          </button>
        </>
      ) : (
        renderOptionsComponent(options, true)
      )}
    </>
  );
}

ExpandablePickupOptions.propTypes = {
  options: PICKUP_OPTIONS_PROP.isRequired,
  seeMoreAfter: PropTypes.number
};

ExpandablePickupOptions.defaultProps = {
  seeMoreAfter: 0
};

export default function PickupOptionDetails({
  pickupOptions,
  breederFirstName
}) {
  return (
    <>
      <h5 className="font-24 mb4">Puppy pick-up locations</h5>
      <p className="mb1 font-16 secondary mb4">
        You can fly or drive to pick up your puppy from {breederFirstName}.
      </p>
      <ExpandablePickupOptions options={pickupOptions} seeMoreAfter={2} />
    </>
  );
}

PickupOptionDetails.propTypes = {
  pickupOptions: PICKUP_OPTIONS_PROP.isRequired,
  breederFirstName: PropTypes.string
};

PickupOptionDetails.defaultProps = {
  breederFirstName: 'your breeder'
};
