import React from 'react';

import BreederProfileTabContent from 'dpl/components/BreederProfile/Show/BreederProfileTabContent';
import BreederProfileTabs from 'dpl/components/BreederProfile/Show/BreederProfileTabs';
import BreederProfileHeader from 'dpl/experiments/exp_breeder_search_updates/breeder_profile/components/BreederProfileHeader';

export default function BreederProfileLayoutContainer() {
  return (
    <div className="BreederProfileLayoutContainer">
      <BreederProfileHeader />
      <BreederProfileTabs />
      <BreederProfileTabContent />
    </div>
  );
}
