import React, { useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';

import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import BreederProfileCurrentLittersSectionSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileCurrentLittersSectionSkeleton';
import Button from 'dpl/common/design_system/Button';
import BreederProfileLitterPuppyCard from 'dpl/components/BreederProfile/Show/BreederProfileLitterPuppyCard';

import useLitters from '../hooks/useLitters';

const COLLAPSED_PUPPIES_COUNT = 5;

export default function PuppiesList() {
  const { breederProfileId } = useSelector(({ server }) => server);
  const { isFetchingCurrentLitters, sortedAvailablePuppies } = useLitters();

  const isCollapsible = sortedAvailablePuppies.length > COLLAPSED_PUPPIES_COUNT;
  const [isCollapsed, toggleIsCollapsed] = useReducer(
    collapsed => !collapsed,
    () => !isCollapsible
  );

  const trackInView = useCallback(() => {
    if (sortedAvailablePuppies.length > 0) {
      sendRudderstackEvent(
        ANALYTICS_EVENTS.SEARCH_RESULT_GROUP_VIEWED_BY_USER,
        {
          view: ANALYTICS_VIEWS.BREEDER_PROFILE,
          view_feature: ANALYTICS_VIEW_FEATURES.PUPPY_CARD,
          view_feature_location:
            ANALYTICS_VIEW_FEATURE_LOCATIONS.AVAILABLE_PUPPIES,
          results: sortedAvailablePuppies.map((puppy, idx) => ({
            dog_id: puppy.id,
            puppy_name: puppy.name,
            breed_id: puppy.breed_id,
            breeder_profile_id: breederProfileId,
            position: idx + 1
            // miles_away, miles_away_ranking
          }))
        }
      );
    }
  });

  if (isFetchingCurrentLitters) {
    return <BreederProfileCurrentLittersSectionSkeleton />;
  }

  if (sortedAvailablePuppies.length === 0) {
    return null;
  }

  const listings = isCollapsed
    ? sortedAvailablePuppies.slice(0, COLLAPSED_PUPPIES_COUNT)
    : sortedAvailablePuppies;

  return (
    <InView
      triggerOnce
      onChange={inView => {
        if (inView) trackInView();
      }}
    >
      <div className="PuppiesList container-xxl flex flex-column pb6">
        <h3 className="mb6 font-24 lh-title">Available puppies</h3>
        <ul className="grid gap-24">
          {listings.map((puppy, idx) => (
            <li key={puppy.id}>
              <BreederProfileLitterPuppyCard
                puppy={puppy}
                breederProfileId={breederProfileId}
                analytics={{
                  position: idx + 1,
                  view_feature_location:
                    ANALYTICS_VIEW_FEATURE_LOCATIONS.AVAILABLE_PUPPIES
                }}
              />
            </li>
          ))}
        </ul>

        {isCollapsible && (
          <Button
            className="font-16 stone-700 w-100 mt4"
            variant="outline"
            size="default"
            onClick={toggleIsCollapsed}
          >
            {isCollapsed ? 'See more puppies' : 'See less puppies'}
          </Button>
        )}
      </div>
    </InView>
  );
}
